<template>
  <div>
    <MallheaderVue :titlename="pagename"></MallheaderVue>
    <div class="ordercontent">
      <van-tabs v-model="tabvalue" @change="changeTab">
        <div v-for="tab in orderstatus" :key="tab">
          <van-tab :title="tab">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <div class="celllist">
                <van-list
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad"
                >
                  <div v-for="(item, index) in list" :key="index">
                    <div class="paytime" v-if="item.order_status == 0">
                      <div class="counttxt">支付倒计时：</div>
                      <div class="countdown">
                        <van-count-down
                          :time="getTimeDiff(item.add_time, item.pay_waiting)"
                          format=" mm 分 ss 秒"
                        />
                      </div>
                    </div>
                    <div
                      class="ordercell"
                      :style="
                        item.order_status == 0
                          ? { borderRadius: '0 0 24px 24px' }
                          : ''
                      "
                    >
                      <div class="celltop">
                        <div class="time">{{ item.add_time }}</div>
                        <div class="status">
                          {{ getorderstatusTxt(item.order_status) }}
                        </div>
                      </div>
                      <div class="goodinfo"   @click="clickpaypage(item)">
                        <img class="goodimg" :src="item.images" />
                        <div class="info">
                          <div class="des">
                            {{ item.name }}
                          </div>
                          <div class="price">
                            价值
                            {{ parseFloat(item.goods_price).toFixed(3) }}USD
                          </div>
                        </div>
                      </div>
                      <div class="cellbottom">
                        <div class="bottom">
                          <span class="paytip">支付</span>
                          <span class="paynum">{{
                            parseFloat(item.pay_amount).toFixed(3)
                          }}</span>
                          <span class="payunit">/{{ item.pay_name }}</span>
                        </div>
                        <div
                          v-if="item.order_status === 0"
                          @click="clickpaypage(item)"
                          class="statusbtn"
                        >
                          支付
                        </div>
                        <div
                          @click="clickdelivery(item,'clickdelivery')"
                          v-if="item.order_status === 2"
                          class="suredelivery"
                        >
                          确认收货
                        </div>
                        <div class="apply" v-if="item.order_status === 3">
                          <div
                            v-if="item.comment_status == 0"
                            @click="clickcomment(item)"
                            class="suredelivery marginR16"
                          >
                            评价
                          </div>
                     <!--     <div
                            v-if="item.comment_status == 1"
                            class="suredelivery marginR16"
                          >
                            已评价
                          </div>-->
                          <div @click="clickapply(item,'apply')" v-if="item.aftersale_status === 0" class="suredelivery">
                            申诉
                          </div>
                          <div @click="clickapply(item,'apply')" v-if="item.aftersale_status > 0" class="suredelivery">
                            查看申诉
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </van-list>
              </div>
            </van-pull-refresh>
          </van-tab>
        </div>
      </van-tabs>
    </div>
    <complaint ref="complaint"></complaint>
    <comment ref="comment"></comment>
    <viewapply ref="viewapply"></viewapply>
    <van-dialog
            v-model="showpwd"
            title="请输入安全码"
            @cancel="showpwd = false"
            @confirm="confirm"
            show-cancel-button
    >
      <van-field
              type="password"
              v-model="password"
              name="安全密码"
              label="安全密码"
              placeholder="请输入安全密码"
      ></van-field>
    </van-dialog>
    <div class="systemcon">
      <van-popup v-model="showIntroduce" :close-on-click-overlay="true">
        <div class="introduce">
          <div class="content">
            <div class="title">
              Letao kind tips<br/>
              乐淘温馨提示</div>
            <div class="introducetxt">
              Congratulations on your successful operation. Please be patient and wait for the block confirmation verification, which is expected to take 1-5 minutes. Please do not make repeated payments. The countdown is over. Please refresh the drop-down menu.
              <br/>
              恭喜您操作成功，请耐心等待区块确认验证，预期需要1-5分钟. 请勿重复支付，倒计时结束，请下拉菜单刷新.
            </div>
            <van-count-down
                    ref="countDown"
                    :time="time"
                    format="ss"
                    @finish="finish"
            />
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { orderList, receiptOrder,viewAppeal } from "@/request/shopapi";
import MallheaderVue from "../../components/mallheader.vue";
import Comment from "./actionsheet/comment.vue";
import Complaint from "./actionsheet/complaint.vue";
import viewapply from "./actionsheet/viewapply.vue";
import { Dialog, Toast } from "vant";

export default {
  components: { MallheaderVue, Complaint, Comment, viewapply },
  data() {
    return {
      list: [],
        time: 10 * 1000,
        showIntroduce: false,
      loading: false,
      finished: false,
      refreshing: false,
      tabvalue: 0,
      pagename: "我的订单",
      orderstatus: ["全部", "待支付", "待发货", "已发货", "已完成"],
      counttime: 60 * 60 * 1000,
      currentpage: 1,
        showpwd: false,
        password: "",
        type:'',
        tmpItem:{},
        applyinfo:{},
        address: "",
        userInfo: {},
        pay_time:0,
    };
  },
    activated() {
        this.userInfo = sessionStorage.getItem("user")
            ? JSON.parse(sessionStorage.getItem("user"))
            : {};
        this.address = sessionStorage.getItem("address");
        this.pay_time = sessionStorage.getItem("pay_type")
            ? sessionStorage.getItem("pay_type")
            : 0;
        let nowTime = parseInt(new Date().getTime()/1000);
        let shen = parseInt(this.pay_time)+60-nowTime;
        console.log(shen)
        if(parseInt(shen)>0){
            this.showIntroduce = true;
            this.time = shen * 1000;
            this.$refs.countDown.reset();
            this.$refs.countDown.start();
        }
    },
  created() {},
  methods: {
      finish() {
          this.showIntroduce = false;
      },
      //查看申诉结果
      getviewAppeal() {
          const params = {
              order_id: this.tmpItem.order_id,
              address: this.address,
              pay_code: this.password,
          };
          viewAppeal(params).then((res) => {
              let success = res.success;
              if (success) {
                  this.applyinfo = res.data;
                  this.$refs.viewapply.preview(this.applyinfo);
              }else {
                  Toast(res.msg);
              }
          });
      },
      confirm() {
          if (this.password.length === 0) {
              Toast("请输入安全码");
              return;
          }
          if (this.type == "apply") {  //查看申诉内容
             this.getviewAppeal();
          }  else if (this.type == "clickdelivery") {
              this.requestreceiptOrder(this.tmpItem);
          }else if(this.type == 'paywite'){
              this.witePay();
          }
      },
    getTimeDiff(comtime, payWite) {
      let sTime = new Date(comtime);
      let min = sTime.getMinutes();
      if (payWite == 1) {
        sTime = sTime.setMinutes(min + 90);
      } else {
        sTime = sTime.setMinutes(min + 60);
      }
      let currenttime = new Date();
      let diff = parseInt(sTime - currenttime);
      console.log(diff);
      return diff;
    },
    clickcomment(item) {
      this.$refs.comment.preview(item);
    },
    clickapply(item,name) {
        this.password = "";
        this.type = name;
        this.tmpItem = item;
      if (item.aftersale_status === 0) {
        this.$refs.complaint.preview(item);
      } else {
          this.$refs.viewapply.preview(item);
          // this.showpwd = true;
      }
    },
    requestreceiptOrder(item) {
      const params = {
        address: this.address,
        pay_code: this.password,
        order_id: item.order_id,
      };
      receiptOrder(params).then((res) => {
        Toast(res.msg);
        let success = res.success;
        if (success) {
          this.onRefresh();
        }
      });
    },
    clickdelivery(item,name) {
        this.password = "";
        this.type = name;
        this.tmpItem = item;
        Dialog.confirm({
            title: "确定收货",
            message: "您确定收货？",
            confirmButtonText: "确认",
            confirmButtonColor: "#FF7220",
        })
            .then(() => {
                this.showpwd = true;
                // on confirm
            })
            .catch(() => {
                // on cancel
            });
    },

    changeTab(value) {
      this.onRefresh();
    },
    getorderstatusTxt(status) {
      // 0待支付1待发货2已发货3已完成’
      let orderstatustxt = "";
      if (status === 0) {
        orderstatustxt = "待支付";
      } else if (status === 1) {
        orderstatustxt = "待发货";
      } else if (status === 2) {
        orderstatustxt = "已发货";
      } else if (status === 3) {
        orderstatustxt = "已完成";
      }
      return orderstatustxt;
    },
    requestOrderlist() {
      const params = {
        address: this.address,
        page: this.currentpage,
        limit: 20,
        order_status: this.tabvalue - 1,
      };
      if (this.tabvalue - 1 < 0) {
        delete params.order_status;
      }
      orderList(params).then((res) => {
        if (this.refreshing) {
          this.refreshing = false;
        }
          this.list = [ ...res.data.list];
        if (this.list.length < 20) {
          this.finished = true;
        }
      });
    },
    clickpaypage(item) {
      this.$router.push({
        path: "/mall/paypage",
        query: {
          id: item.order_id,
        },
      });
    },
    onLoad() {
      this.requestOrderlist();
        // this.currentpage++;
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.list = [];
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .van-count-down {
    font-size: 30px;
    color: white;
    text-align: center;
  }
  .systemcon {
    ::v-deep .van-popup {
      border-radius: 6px;
    }
  }
  .introduce {
    width: 700px;
    height: 100%;

    background-color: #078538;
    .content {
      padding: 24px;
      color: white;
      font-weight: bold;
      .title {
        text-align: center;
        font-size: 38px;
      }
      .introducetxt {
        margin-top: 15px;
        font-size: 30px;
        line-height: 50px;
      }
    }
  }
.marginR16 {
  margin-right: 16px;
}
.ordercontent {
  background: #ebeef2;
  min-height: 100vh;
  padding: 0 24px;
  ::v-deep .van-tabs__nav {
    background-color: transparent;
  }
  ::v-deep .van-tabs__line {
    width: 28px;
    height: 4px;
    border-radius: 4px;
    opacity: 1;
    background: rgba(255, 114, 32, 1);
    bottom: 20px;
  }
  .paytime {
    position: relative;
    display: flex;
    padding: 8px 24px;
    width: 702px;
    height: 48px;
    border-radius: 24px 24px 0 0;
    opacity: 1;
    background: #faf8f6;
    .counttxt {
      width: 144px;
      height: 36px;
      opacity: 1;
      color: rgba(156, 152, 150, 1);
      font-size: 24px;
      font-weight: 400;
      font-family: "PingFang SC";
      text-align: left;
      line-height: 36px;
    }
    .countdown {
      ::v-deep .van-count-down {
        color: rgba(203, 52, 241, 1);
        font-size: 12px;
        font-weight: 500;
        font-family: "DIN";
      }
    }
  }

  .celltop {
    display: flex;
    justify-content: space-between;
    .time {
      color: rgba(105, 98, 95, 1);
      font-size: 24px;
      font-weight: 400;
      font-family: "PingFang SC";
      text-align: left;
      line-height: 36px;
    }
    .status {
      width: 116px;
      height: 36px;
      border-radius: 16px;
      opacity: 1;
      background: rgba(252, 242, 255, 1);
      color: rgba(203, 52, 241, 1);
      font-size: 20px;
      text-align: center;
      line-height: 36px;
    }
  }
  .goodinfo {
    margin-top: 16px;
    display: flex;
    .goodimg {
      width: 112px;
      height: 112px;
      background-color: #ffaf37;
      margin-right: 16px;
    }
    .info {
      .des {
        width: 526px;
        color: rgba(41, 33, 29, 1);
        font-size: 24px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
      }
      .price {
        margin-top: 8px;
        color: rgba(105, 98, 95, 1);
        font-size: 24px;
        font-weight: 400;
        text-align: left;
      }
    }
  }
  .cellbottom {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    .bottom {
      font-size: 24px;
      font-family: "PingFang SC";
      line-height: 36px;
      .paytip {
        color: rgba(156, 152, 150, 1);
        font-size: 24px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 36px;
        margin-right: 8px;
      }
      .paynum {
        color: rgba(241, 27, 27, 1);
        font-weight: 500;
        text-align: left;
        font-size: 32px;
        font-family: "DIN";
        line-height: 44px;
      }
      .payunit {
        color: rgba(241, 27, 27, 1);
        font-weight: 400;
        text-align: left;
        font-size: 24px;
        font-family: "PingFang SC";
        line-height: 36px;
      }
    }
    .suredelivery {
      width: 136px;
      height: 52px;
      border-radius: 50px;
      opacity: 1;
      background: #ebeef2;
      color: #69625f;
      font-size: 24px;
      font-weight: 400;
      font-family: "PingFang SC";
      text-align: center;
      line-height: 52px;
    }
    .statusbtn {
      width: 136px;
      height: 52px;
      border-radius: 50px;
      opacity: 1;
      background: rgba(255, 247, 242, 1);
      color: rgba(255, 114, 32, 1);
      font-size: 24px;
      font-weight: 400;
      font-family: "PingFang SC";
      text-align: center;
      line-height: 52px;
    }
    .apply {
      display: flex;
    }
  }
  .ordercell {
    width: 702px;
    height: 280px;
    border-radius: 24px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    padding: 24px;
    margin-bottom: 24px;
    position: relative;
  }
}
</style>
