<template>
  <div>
    <van-action-sheet v-model="showAction" :title="'评价'" class="modalclass">
      <div class="combox">
        <div class="star">
          <div class="startxt">商品评分</div>
          <div class="startimg">
            <div v-for="i in 5" :key="i">
              <img
                v-if="i <= starindex"
                @click="selectstar(i)"
                class="staricon"
                :src="require('@/assets/images/mall/starorange.png')"
              />
              <img
                v-else
                @click="selectstar(i)"
                class="staricon"
                :src="require('@/assets/images/mall/stargray.png')"
              />
            </div>
          </div>
        </div>
        <div class="inputbox">
          <van-field
            v-model="commtxt"
            rows="3"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="评价内容"
            show-word-limit
          />
        </div>
        <div class="btncontent">
          <div class="btn" @click="showpwd1 = true">保存</div>
        </div>
      </div>
    </van-action-sheet>
    <van-dialog
            v-model="showpwd1"
            title="请输入安全码"
            @cancel="showpwd1 = false"
            @confirm="submit"
            show-cancel-button
    >
      <van-field
              type="password"
              v-model="password"
              name="安全密码"
              label="安全密码"
              placeholder="请输入安全密码"
      ></van-field>
    </van-dialog>
  </div>
</template>

<script>
import { evaluateOrder } from "@/request/shopapi";
import { Toast } from "vant";
export default {
  name: "EtWebComment",

  data() {
    return {
      showAction: false,
      commtxt: "",
      orderinfo: {},
      starindex: 0,
        userInfo: {},
        address: "",
        password: "",
        showpwd1:false,
    };
  },

  mounted() {},

  methods: {
    submit() {
      if (this.commtxt.length === 0) {
        Toast("请输入内容");
        return;
      }
      if (this.starindex === 0) {
        Toast("请打分");
        return;
      }
        if (this.password.length === 0) {
            Toast("请输入安全码");
            return;
        }
      this.requestevaluateOrder();
    },
    requestevaluateOrder() {
      const params = {
        address: this.address,
        pay_code: this.password,
        order_id: this.orderinfo.order_id,
        content: this.commtxt,
        goods_rank: this.starindex,
      };
      evaluateOrder(params).then((res) => {
        let success = res.success;
        Toast(res.msg);
        if (success) {
          this.showAction = false;
        }
      });
    },
    preview(item) {
      this.orderinfo = item;
      this.showAction = true;
      this.commtxt = "";
      this.starindex = 0;
        this.userInfo = sessionStorage.getItem("user")
            ? JSON.parse(sessionStorage.getItem("user"))
            : {};
        this.address = sessionStorage.getItem("address");
    },
    selectstar(index) {
      this.starindex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.modalclass {
  border-radius: 12px 12px 0px 0px;
  background: white;
  .combox {
    padding: 32px 32px 100px;
    .star {
      display: flex;
      align-items: center;
      .startxt {
        color: rgba(0, 0, 0, 0.9);
        font-size: 24px;
        font-weight: 700;
        font-family: "PingFang SC";
        text-align: right;
        line-height: 36px;
        margin-right: 34px;
      }
      .startimg {
        display: flex;
        justify-content: start;
        .staricon {
          width: 32px;
          height: 32px;
          margin-right: 24px;
        }
      }
    }
    .inputbox {
      margin-top: 16px;
      width: 686px;
      min-height: 206px;
      border-radius: 16px;
      opacity: 1;
      border: 2px solid rgba(255, 223, 204, 1);
      background: rgba(250, 247, 245, 1);
      ::v-deep .van-cell {
        background-color: transparent;
      }
    }
    .btncontent {
      display: flex;
      justify-content: center;
      margin-top: 74px;
      .btn {
        width: 478px;
        height: 72px;
        border-radius: 74px;
        opacity: 1;
        background: rgba(255, 114, 32, 1);
        color: rgba(238, 238, 238, 1);
        font-size: 28px;
        font-weight: 700;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 72px;
      }
    }
  }
}
</style>
