<template>
  <div>
    <van-action-sheet v-model="showAction" :title="'申诉'" class="modalclass">
      <div class="combox">
        <div class="inputbox">
          <van-field
            v-model="message"
            rows="3"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="问题描述!"
            show-word-limit
          />
        </div>
        <div class="notice marginT16">
          注意:
          <div class="tip">1.申诉后客服会介入，请配合</div>
          <div class="tip">2.对于恶意申诉或是恶意发货平台会给予惩罚</div>
        </div>
        <div class="btncontent">
          <div class="btn" @click="showpwd1=true">申诉</div>
        </div>
      </div>
    </van-action-sheet>
    <van-dialog
            v-model="showpwd1"
            title="请输入安全码"
            @cancel="showpwd1 = false"
            @confirm="clickApply"
            show-cancel-button
    >
      <van-field
              type="password"
              v-model="password"
              name="安全密码"
              label="安全密码"
              placeholder="请输入安全密码"
      ></van-field>
    </van-dialog>
  </div>
</template>

<script>
import { appealOrder } from "@/request/shopapi";
import { Toast } from "vant";

export default {
  data() {
    return {
      showAction: false,
      message: "",
      orderdetail: {},
        userInfo: {},
        address: "",
        password: "",
        showpwd1:false,

    };
  },
  methods: {
    requestappealOrder() {

      const params = {
        address: this.address,
        pay_code: this.password,
        order_id: this.orderdetail.order_id,
        reason: this.message,
      };
      appealOrder(params).then((res) => {
        Toast(res.msg);
        if (res.success) {
          this.showAction = false;
        }
      });
    },
    clickApply() {
      if (this.message.length === 0) {
        Toast("请输入内容");
        return;
      }
        if (this.password.length === 0) {
            Toast("请输入安全码");
            return;
        }
      this.requestappealOrder();
    },
    preview(item) {
      this.showAction = true;
      this.orderdetail = item;
      this.message = "";
        this.userInfo = sessionStorage.getItem("user")
            ? JSON.parse(sessionStorage.getItem("user"))
            : {};
        this.address = sessionStorage.getItem("address");

    },
  },
};
</script>

<style lang="scss" scoped>
.marginT16 {
  margin-top: 16px;
}
.modalclass {
  border-radius: 12px 12px 0px 0px;
  background: white;
  .combox {
    padding: 32px 32px 100px;
    .inputbox {
      width: 686px;
      min-height: 206px;
      border-radius: 16px;
      opacity: 1;
      border: 2px solid rgba(255, 223, 204, 1);
      background: rgba(250, 247, 245, 1);
      ::v-deep .van-cell {
        background-color: transparent;
      }
    }
    .notice {
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      line-height: 36px;
      font-family: "PingFang SC";
      .tip {
        color: rgba(156, 152, 150, 1);
        font-size: 24px;
        font-weight: 400;
        text-align: left;
        line-height: 36px;
        font-family: "PingFang SC";
      }
    }
    .btncontent {
      display: flex;
      justify-content: center;
      margin-top: 74px;
      .btn {
        width: 478px;
        height: 72px;
        border-radius: 74px;
        opacity: 1;
        background: rgba(255, 114, 32, 1);
        color: rgba(238, 238, 238, 1);
        font-size: 28px;
        font-weight: 700;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 72px;
      }
    }
  }
}
</style>
